/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

export const About = () => (
     <Container>
          <Row>
               <Col>
                    <div className="pt-5">
                         <h6 className="caption-text">LEARN MORE</h6>
                         <h1>About me</h1>
                         
                    </div>
                    <div className="pt-3">
                    <p>
                    <h3>MS in Computer Science & Engineering @ The State University of New York at Buffalo.</h3>
                         </p>
                         <p>
                         I love solving algorithmic problems and am equally interested in building and breaking things. I'm currently on a quest to make computers intelligent.                      </p>
                    </div>
               </Col>
          </Row>
          <Row>
               <Col>
                    <div className="pt-5">
                         <h3>Skills</h3>
                    </div>
                    <div className="pt-3">
                    <ul>
                         <li>Deep Learning Algorithms</li>
                         <li>Spring Boot</li>
                         <li>OpenCV</li>
                         <li>Tensorflow</li>
                         <li>REST API</li>
                         <li>MySQL</li> 
                         <li>Java</li>
                         <li>Python</li>
                    </ul>
                    </div>
               </Col>
          </Row>
          <Row>
               <Col>
                    <div className="social-media">
                         <a href = "mailto: singh23@buffalo.edu" target="_blank" rel="noopener noreferrer"  className="pr-3" title="Send Email"><p>Email</p></a>
                         <a href="https://github.com/singhamritanshu" target="_blank" rel="noopener noreferrer" className="pr-3" title="Join on Github"><p>Github</p></a>
                         <a href="https://www.linkedin.com/in/singhamritanshu" target="_blank" rel="noopener noreferrer" className="pr-3" title="Join on Linkedin"><p>Linkedin</p></a>
                         
                    </div>
               </Col>
          </Row>
     </Container>
)   